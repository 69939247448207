<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-clipboard-list"></i> ข้อมูลรายการเพิ่มลด </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-product-category />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import pageProductCategory from "./view/page.product_category.vue";

  export default {
    name: 'product_category-index',
    methods: {},
    components: {
      pageProductCategory,
    }
  };
</script>
<style>

</style>
