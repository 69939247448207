<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8 mb-4">
            <b-dropdown id="dropdown-1" text="ประเภทรายการเพิ่มลด" class="dropdown_input_search mr-4 mb-2">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.filters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                    <input type="checkbox" class="checkbox_search_input" :name="`checkbox_group_name${v.id}`"
                      :id="`checkbox_group_name${v.id}`" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label" v-bind:for="`checkbox_group_name${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="รายการย่อย" class="dropdown_input_search mr-4 mb-2">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.sub_cat" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                    <input type="checkbox" class="checkbox_search_input" :name="`checkbox_product_cat_sub_cat${v.id}`"
                      :id="`checkbox_product_cat_sub_cat${v.id}`" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label" v-bind:for="`checkbox_product_cat_sub_cat${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
          </div>
          <div class="col-sm-4">
            <b-input-group >
              <b-input-group-append>
                <b-input-group-text  class="input_border_radius_left_top_bt">
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา เช่น ชื่อรายการเพิ่มลด, ประเภทรายการเพิ่มลด, รายละเอียด"
                            v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()">
              </b-form-input>
              <b-input-group-append>
                <b-button text="Button" variant="primary" @click="searchHandler()" style="width: 86px;">
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12">
            <hr class="mt-5 mb-5">
          </div>
          <div class="col-lg-3 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2">
              จำนวน: <strong style="color: #000000; font-weight: bold;">{{countFilter()}} รายการ</strong> จาก {{criteria.filters.length+criteria.sub_cat.length | numberIntFormat}} รายการ
            </span>
          </div>
          <div class="col-md-7">
            <template v-for="(select, index) in criteria.filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="index" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.sub_cat">
              <span class="badge bage_selected_checkbox_search m-2" :key="index" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="label_algin_right text-underline m-2" style="color: #848484; cursor: pointer;" @click="unSelectAllFilter()">ล้างทั้งหมด</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-4 text-right">
      <b-button size="md" variant="primary" :to="'/product-category/add'" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-plus"></i></b-button>
      <b-button size="md" variant="danger" v-b-modal.dialog_product_category_remove :disabled="checkDisabledDelete()" class="style_btn" v-if="permission.actionData.flag_delete"><i class="far fa-trash-alt"></i></b-button>
    </div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th style="min-width: 50px; width: 1px;">เลือก</th>
                  <th @click="sortHandler('name')">ชื่อรายการเพิ่มลด
                    <div class="float-right" v-if="criteria.orderBy=='name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th @click="sortHandler('type_name')">ประเภทรายการเพิ่มลด
                    <div class="float-right" v-if="criteria.orderBy=='type_name'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th @click="sortHandler('detail')">รายละเอียด
                    <div class="float-right" v-if="criteria.orderBy=='detail'"><i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i></div>
                  </th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in items" :key="data.id"
                    :id="`checkbox_product_category_index${index}`">
                  <td class="text-center checkbox_style" >
                    <b-form-checkbox :name="`checkbox_group${data.id}`" v-model="data.selected" @change="selectedProductCategory(...arguments, index)"/>
                  </td>
                  <td>{{data.name}}</td>
                  <td>{{data.type_name}}</td>
                  <td>{{data.detail}}</td>
                  <td class="text-center" >
                    <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                      <i class="fas fa-pen" @click="onEditHandler(data.id)" title="แก้ไข"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="items.length === 0" class="text-center">
                  <td colspan="5">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div  class="col-lg-1 col-md-2 col-3"
              style="margin-top: 30px;">
          <select class="form-control mb-2"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages"
                    :key="data"
                    :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3"  v-if="paging.rowTotal > 0">
            <div  class="col-xl-11 col-lg-10 mb-2 text-right"
                  style="margin-top: 30px;">
              <label  class="form-label"
                      style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;">
                แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ </strong>
              </label>
              <paginate v-model="paging.page"
                        :first-last-button="true"
                        :page-count="paging.pageTotal"
                        :page-range="5"
                        :margin-pages="2"
                        :prev-text="'&lt;'"
                        :next-text="'&gt;'"
                        :first-button-text="'&verbar;&lt;'"
                        :last-button-text="'&gt;&verbar;'"
                        :click-handler="changePage"
                        :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                        :page-class="'page-link'"
                        :prev-class="'page-link prev_paging'"
                        :next-class="'page-link next_paging'">
              </paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label  class="form-label"
                      style="font-size: 12px;
                      font-family: Sarabun-Light;">
                Go to page
              </label>
              <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <dialogProductCategoryRemove :items="items" @successFuction="initialData"></dialogProductCategoryRemove>
    </div>
  </div>
</template>
<script>
import config from '../../../util/config';
import dialogProductCategoryRemove from './dialog.product_category.remove.vue';
import $ from 'jquery';
  export default {
    name: 'product_category-view-page-product_category',
    data() {
      return {
        permission:{
          appSlug: 'product-category',
          actionData: {}
        },
        criteria: {
          search: "",
          orderBy: "",
          ascDesc: "",
          filters: [],
          sub_cat: []
        },
        items: [],
        paging: {
          page: 1,
          limit: config.pagination.limit,
          pages: config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        }
      };
    },
    props: [],
    methods: {
      onEditHandler(id){
        this.SessionStorageUtils.setSession("page-product-category", id);
        this.$router.push(`/product-category/edit/${id}`);
      },

      /******* local function ******/
      changeLimit(){
        this.paging.page = 1;
        this.getListItems();
      },
      changePage (page) {
        this.paging.page = parseInt(""+page);
        this.getListItems();
      },
      sortHandler(orderBy){
        if(orderBy!=this.criteria.orderBy){
          this.criteria.ascDesc = "asc";
        }else{
          this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
        }
        this.criteria.orderBy = orderBy;
        this.getListItems();
      },
      searchHandler(){
        this.paging.page = 1;
        this.getListItems();
      },
      async getListItems () {
        let filters = [];
        this.criteria.filters.map((v)=>{
          if(v.selected){
            filters.push(v.id);
          }
        });
        filters = filters.join(",");
        let subCats = [];
        this.criteria.sub_cat.map((v)=>{
          if(v.selected){
            subCats.push(v.id);
          }
        });
        subCats = subCats.join(",");
        let params = `search=${this.criteria.search}&filters=${filters}&sub_cats=${subCats}`;
        params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
        const result = await this.HttpServices.getData(`/ab_product_cat?${params}`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          var total = Math.ceil(data.rowTotal/this.paging.limit);
          this.paging.rowTotal = data.rowTotal;
          this.paging.pageTotal = total;
          this.items = data.data;
          this.paging.pageSizes = [];
          for(let i=1;i<=total;i++){
            this.paging.pageSizes.push({id: i, text: i});
          }
        }else{
          this.paging.pageSizes = [];
          this.paging.rowTotal = 0;
          this.items = [];
        }
      },
      checkDisabledDelete(){
        let resp = true;
        for(const v of this.items){
          if(v.selected){
            resp = false;
            break;
          }
        }

        return resp;
      },
      async getFilterGroups(){
        const result = await this.HttpServices.getData(`/ab_product_cat/getCountByGroup`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          this.criteria.filters = data.product_types;
          const subCats = data.sub_cat;
          if(subCats){
            this.criteria.sub_cat.push({id: 1, name: "ใช่", filter_count: 0});
            this.criteria.sub_cat.push({id: 0, name: "ไม่ใช่", filter_count: 0});
            for(const v of this.criteria.sub_cat){
              for(const vv of subCats){
                let filterCount = 0;
                if(v.id==vv.id){
                  filterCount = vv.filter_count;
                  v.filter_count = filterCount;
                }
              }
            }
          }
        }
      },
      countFilter(){
        const filters = this.criteria.filters.filter((v)=>v.selected);
        const subCats = this.criteria.sub_cat.filter((v)=>v.selected);
        return filters.length+subCats.length;
      },
      unSelectFilter(item){
        item.selected = false;
        this.searchHandler();
      },
      unSelectAllFilter(){
        this.criteria.filters.map((v)=>{
          v.selected = false;
        });
        this.criteria.sub_cat.map((v)=>{
          v.selected = false;
        });
        this.searchHandler();
      },
      async initialData(){
        this.getFilterGroups();
        this.getListItems();
      },
      selectedProductCategory (value, index) {
        if (value) {
          $(`tr#checkbox_product_category_index${index}`).attr('class', 'highlight');
        } else {
          $(`tr#checkbox_product_category_index${index}`).removeClass('highlight');
        }
      }
      /******* local function ******/
    },
    async mounted() {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      await this.initialData();
      this.SessionStorageUtils.deleteSession("page-product-category");
    },
    components: {
      dialogProductCategoryRemove : dialogProductCategoryRemove
    }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
